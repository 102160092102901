
export default {
  name: 'AtomLoader',
  props: {
    size: {
      required: false,
      type: Object,
      default: () => {
        return {
          width: 75,
          height: 75
        }
      }
    },
    backdropColor: {
      required: false,
      type: String,
      default: 'rgba(0, 0, 0, 0.1)'
    },
    accentColor: {
      required: false,
      type: String,
      default: 'rgba(0, 0, 0, 0.2)'
    },
    thickness: {
      required: false,
      type: Number,
      default: 15
    }
  }
}

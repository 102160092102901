
export default {
  name: 'AtomCustomer',
  props: {
    image: {
      required: true,
      type: String,
      default: ""
    },
    alt: {
      required: true,
      type: String,
      default: ""
    },
    maxHeight: {
      required: false,
      type: Number,
      default: 500
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters['base/cdnBase']}/${url}`
    }
  }
}
